<template>
  <div class="activationSuccess">
    <div class="success-img-box">
      <img :src="successImgSrc" alt="" srcset="">
    </div>
    <div class="vip-phone">
      <img :src="vipIconSrc" alt="" srcset="">
      <span class="user-phone">{{homeData.phone}}</span>
    </div>
    <p style="text-align: center">恭喜您！您的会员已激活账号成功</p>
    <!--      预留显示会员号部分-->
    <div class="bottom-content-box">

    </div>
  </div>
</template>

<script>
export default {
  name: "activationSuccess",
  data() {
    return {
      successImgSrc:require("@/assets/img/6.png"),
      vipIconSrc:require("@/assets/img/1.png"),
      homeData: {
        phone: '18617668688',
        username: '张三',
        mchntId: '998889'
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let query = this.$route.query;
      this.homeData.phone = query.phone;
      this.homeData.username = query.username;
      this.homeData.mchntId = query.mchntId;
    }
  }
}
</script>

<style scoped lang="less">
.activationSuccess{
  width: 100%;
  height:100%;
  .success-img-box{
    width: 30%;
    margin: 30px auto 10px auto;
    img{
      width: 100%;
    }
  }
  .vip-phone{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 26px;
      vertical-align: middle;
    }
    .user-phone{
      color: #56ad53;
      font-weight: 700;
      padding-left: 5px;
    }
  }
  .bottom-content-box{

  }
}
</style>